<template>
  <div :id="name" class="relative flex w-full flex-col items-start space-y-px">
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
    <div
      :ref="(el) => (refs[name] = el)"
      class="group relative flex h-10 w-full flex-row items-center justify-start gap-2 rounded-xl bg-white"
      :class="{ 'px-4 py-3 ': !ghost }"
      @focus="isInputFocused = true"
      @blur="isInputFocused = false"
    >
      <UiInputMenu
        v-model="computedPhoneCountry"
        :items="countryCodes"
        :name="`${name}_phone_input`"
        placeholder="Find country"
        :width="300"
        :read-only
        search
        flag
        :class="[{ 'z-30 ': isInputFocused }, disabled ? 'z-30' : 'group-hover:z-30']"
      >
        <template #activator="{ onClick }">
          <small class="flex flex-row items-center">
            <transition name="fade" mode="out-in">
              <div
                :key="currentPhoneCountry?.iso2.toLowerCase()"
                class="flex h-6 min-w-[48px] flex-row items-center"
                :class="[
                  {
                    'rounded-lg border-[1.5px] border-solid px-2': !ghost,
                    'cursor-default text-black-60': disabled || readOnly,
                    'cursor-pointer': !disabled,
                    'hover:bg-black-03': !disabled && ghost,
                  },
                  modelValue.phone_country_id || disabled
                    ? 'border-black-20'
                    : error
                    ? 'border-error-100 text-error-100'
                    : 'border-black-20',
                ]"
                @click="disabled ? null : onClick()"
              >
                <div v-if="currentPhoneCountry" class="flex flex-row items-center gap-2">
                  <UiFlagIcon :name="currentPhoneCountry.iso2?.toLowerCase()" />
                  <div v-if="!hideCountryCodes">+{{ currentPhoneCountry.phone_code }}</div>
                </div>
                <h5 v-else class="text-subhead-3">Code</h5>
                <UiIcon
                  name="chevron-big-filled-down"
                  class="transition-all duration-200"
                  :class="{ 'rotate-180': isInputFocused }"
                ></UiIcon>
              </div>
            </transition>
            <span v-if="ghost" class="cursor-text">
              {{ modelValue.phone || '-' }}
            </span>
          </small>
        </template>
      </UiInputMenu>
      <div v-if="!ghost">
        <input
          :id="name"
          v-model="computedPhone"
          :name="name"
          type="number"
          autocomplete="off"
          :placeholder="placeholder"
          :disabled="disabled"
          class="text-body-2 peer relative h-[20px] w-full flex-1 border-none bg-transparent outline-none [appearance:textfield] placeholder:text-sm placeholder:font-normal placeholder:leading-5 group-hover:z-20 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          :class="[{ 'placeholder:text-error-100': error }, disabled ? 'z-30' : '']"
        />
        <div
          class="absolute left-0 top-0 size-full rounded-xl border-[1.5px] border-solid outline-none transition-colors duration-200"
          :class="
            error
              ? 'border-error-100 peer-hover:border-error-100 '
              : 'border-black-20 hover:border-primary-50 active:border-primary-120 peer-hover:border-primary-50  peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05 '
          "
        ></div>
      </div>
    </div>
    <div v-if="!ghost" class="absolute -bottom-4 h-4 w-full">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import { DEFAULT_DEBOUNCE_INPUT_TIME } from '@/constants'
import type { Country, InputItem, Lead, Phone } from '@/types'

const emits = defineEmits(['update:modelValue', 'update:debounced'])

type Props = {
  modelValue: Lead | Phone
  important?: number
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  error?: string
  ghost?: boolean
  hideCountryCodes?: boolean
  readOnly?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  important: 0,
  label: '',
  placeholder: '',
  error: '',
  hideCountryCodes: false,
})

// Lazy load
const countries = ref<Country[]>([])
const countryCodes = ref<InputItem[]>([])
onNuxtReady(async () => {
  countries.value = await useCountries()
  countryCodes.value = countries.value.map((c) => {
    return {
      text: `${c.name} +${c.phone_code}`,
      value: c.id,
      important: props.modelValue.phone_country_id === c.id,
      icon: c.iso2.toLowerCase(),
    } as InputItem
  })
})

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}
const isInputFocused = ref(false)

const computedPhoneCountry = computed({
  get() {
    return props.modelValue.phone_country_id
  },
  set(value) {
    emits('update:modelValue', { ...props.modelValue, phone_country_id: value })
    emits('update:debounced')
  },
})
const computedPhone = computed({
  get() {
    return props.modelValue.phone
  },
  set(value) {
    emits('update:modelValue', { ...props.modelValue, phone: value })
  },
})

watchDebounced(
  computedPhone,
  () => {
    emits('update:debounced')
  },
  { debounce: DEFAULT_DEBOUNCE_INPUT_TIME }
)

const currentPhoneCountry = computed(() => countries.value.find((c) => c.id === computedPhoneCountry.value))
</script>

<style scoped></style>
